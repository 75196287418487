import { api } from 'api'

export const agentLoginApi = payload => {
  return api.post({ endpoint: '/auth/agent-with-pin', body: payload })
}

export const screenEmailApi = async email => {
  return api.get({ endpoint: `/auth/screenEmail?email=${email}` })
}

export const failUserSignupApi = async payload => {
  return api.post({ endpoint: '/auth/user-signup-fail', body: payload })
}

export const failUserUpdatepApi = async payload => {
  return api.post({ endpoint: '/auth/user-update-fail', body: payload })
}
