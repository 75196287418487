import React, { useState, useContext, useEffect, useRef } from 'react'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { agentVerifyEmail } from 'api/agents'
import { screenEmail } from 'api/auth'
import { SessionContext } from 'contexts/Session'
import { useAccountCreation } from 'contexts/AccountCreation'
import { useLocalForage } from 'contexts/LocalForage'
import Fieldset from '../FormComponents/Fieldset'
import FieldsetItem from '../FormComponents/Fieldset/FieldsetItem'
import Input from '../FormComponents/Input/Input'
import ButtonCta from '../Button/ButtonCta'
import Section from '../Section'
import Wrapper from '../Wrapper'
import styled from 'styled-components'
import FieldMessage from '../FormComponents/FieldMessage'
import { testPassword, emailRegex } from 'utils/validators'
import { NO_INTERNET_TEXT } from 'utils/constants'
import { successColor } from 'styles/colors'
import Icon from 'components/Icon'
import PasswordInput from '../FormComponents/Input/PasswordInput'

const CustomValidatorCheckMark = styled(Icon)`
  position: absolute;
  top: 55px;
  right: 24px;
  transform: translateY(-50%);
`

const CreationFormFirstStep = () => {
  const { isOnline } = useContext(SessionContext)
  const { user, setUser, resetUser, nextStep, setFlow, startTimer } = useAccountCreation()
  const { getStoredItem } = useLocalForage()
  const [accountMessage, setAccountMessage] = useState({
    type: null,
    message: null
  })
  const [accountMessageEmail, setAccountMessageEmail] = useState({
    type: null,
    message: null,
    icon: null
  })
  const [showPasswordField, setShowPasswordField] = useState(false)
  const [buttonText, setButtonText] = useState('Create Account')
  const [accountExist, setAccountExist] = useState(false)
  const [returningUserInfo, setReturningUserInfo] = useState({})
  const [emailChecking, setEmailChecking] = useState(false)
  const [disableReturningConsumerFlowButton, setDisableReturningConsumerFlowButton] = useState(false)
  const inputRef = useRef()

  const debounce = (func, delay) => {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => func(...args), delay)
    }
  }

  const checkEmail = async event => {
    const email = event.target.value

    resetDefaultState()

    if (emailRegex.test(email)) {
      setEmailChecking(true)

      if (isOnline) {
        try {
          const response = await agentVerifyEmail({ email })
          if (response.emailExists === true) {
            if (response.isUserBeforeDesiredDate === false) {
              setAccountMessage({
                type: 'error',
                message: 'You already have an account. Ask your brand ambassador for more details',
                icon: 'cross-red'
              })
              setDisableReturningConsumerFlowButton(true)
            } else {
              setAccountMessage({
                type: 'success',
                message: response.firstName ? `Welcome back ${response.firstName}` : 'Welcome back'
              })
            }
            setAccountExist(true)
            setShowPasswordField(false)
            setReturningUserInfo(response)
            setButtonText('Log in')
          } else {
            const response = await screenEmail(email)

            // Error
            if (response.error === 'Email account invalid') {
              setDisableReturningConsumerFlowButton(true)
              setAccountMessageEmail({
                type: 'error',
                message: 'Email account invalid',
                icon: 'cross-red'
              })
            } else if (response.status === 'unknown' || response.error === 'Email domain invalid') {
              setDisableReturningConsumerFlowButton(true)
              setAccountMessageEmail({
                type: 'error',
                message: 'Email domain invalid',
                icon: 'cross-red'
              })
            } else {
              // Success
              setDisableReturningConsumerFlowButton(false)
            }
          }
        } catch (error) {
          setDisableReturningConsumerFlowButton(true)
          setAccountMessageEmail({
            type: 'error',
            message: 'An error has occurred during agent validation, please ask an agent to login again',
            icon: 'cross-red'
          })
        }
      } else {
        setAccountMessage({
          type: 'error',
          message: NO_INTERNET_TEXT,
          icon: 'cross-red'
        })
      }
      setEmailChecking(false) // Reset emailChecking to false after the check is complete
    }
  }

  useEffect(() => {
    if (inputRef && inputRef.current) {
      const debouncedCheckEmail = debounce(checkEmail, 500)
      inputRef.current.addEventListener('input', debouncedCheckEmail)

      // Call checkEmail on mount if emailValue is not empty and valid
      if (user.email && emailRegex.test(user.email)) {
        checkEmail({ target: { value: user.email } })
      }
    }
  }, [])

  const resetDefaultState = () => {
    setAccountExist(false)
    resetUser()
    setReturningUserInfo({})
    setShowPasswordField(true)
    setButtonText('Create Account')
    setAccountMessage({
      type: 'error',
      message: '',
      icon: null
    })
    setDisableReturningConsumerFlowButton(false)
    setAccountMessageEmail({ type: '', message: '', icon: null })
  }

  const handleFirstStepSubmit = async values => {
    const agentSource = await getStoredItem('agentSource')
    const agentErpSource = await getStoredItem('agentErpSource')

    if (accountExist) {
      setFlow('ReturningConsumer')
      setUser({
        email: values.email,
        ...returningUserInfo
      })
      nextStep()
    } else {
      setFlow('AccountCreation')
      const userValue = {
        ...user,
        email: values.email,
        password: values.password,
        agentSource,
        utmSource: agentErpSource
      }
      if (!agentErpSource?.length > 0) delete userValue.utmSource

      setUser(userValue)
      nextStep()
    }
  }

  const getValidationSchema = () => {
    let schema = yup.object().shape({
      email: yup
        .string()
        .email('Email address is invalid')
        .required('Email is required')
        .matches(emailRegex, 'Email address is invalid')
    })

    if (showPasswordField) {
      schema = schema.concat(
        yup.object().shape({
          password: yup
            .string()
            .required('Password is required')
            .test({
              firstName: 'password-validator',
              test(value) {
                return testPassword(value, this)
              }
            })
        })
      )
    }

    return schema
  }

  return (
    <Section title={'Enter your email to start'}>
      <Wrapper>
        <Formik
          initialValues={{ email: user.email || '', password: user.password || '' }}
          validationSchema={getValidationSchema()}
          onSubmit={handleFirstStepSubmit}
          validateOnChange
        >
          {({ errors, touched, values, handleChange, handleBlur, isSubmitting }) => {
            const modifiedHandleChange = event => {
              // Start timer to track account creation completion time
              if (event.target.value.length === 5) startTimer()
              handleChange(event)
            }

            return (
              <Form>
                <Fieldset>
                  <FieldsetItem xs="1-1">
                    <div style={{ position: 'relative' }}>
                      <Input
                        type="email"
                        label="Email address"
                        name="email"
                        placeholder="johndoe@domain.com"
                        onChange={modifiedHandleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        autoComplete="off"
                        meta={{
                          error: errors.email,
                          touched: touched.email
                        }}
                        ref={inputRef}
                        style={{
                          borderBottom: accountExist ? `1px solid ${successColor}` : '1px solid black'
                        }}
                      />
                      {!errors.email && values.email.length > 1 && (
                        <CustomValidatorCheckMark icon={'checkmark-green'} small />
                      )}
                    </div>
                  </FieldsetItem>

                  {accountMessageEmail.message && accountMessageEmail.message.length > 0 && (
                    <FieldsetItem xs="1-1" noPaddingTop>
                      <FieldMessage type={accountMessageEmail.type} icon={accountMessageEmail.icon}>
                        {accountMessageEmail.message}
                      </FieldMessage>
                    </FieldsetItem>
                  )}

                  {showPasswordField && (
                    <FieldsetItem xs="1-1">
                      <PasswordInput
                        value={values.password}
                        meta={{
                          error: errors.password,
                          touched: touched.password
                        }}
                        label="Create new password"
                        name="password"
                        placeholder="****"
                        onChange={modifiedHandleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                      />
                    </FieldsetItem>
                  )}

                  {accountMessage.message && accountMessage.message.length > 0 && (
                    <FieldsetItem xs="1-1" noPaddingTop>
                      <FieldMessage type={accountMessage.type} icon={false}>
                        {accountMessage.message}
                      </FieldMessage>
                    </FieldsetItem>
                  )}
                  <FieldsetItem xs="1-1">
                    <ButtonCta type="submit" disabled={disableReturningConsumerFlowButton} isLoading={emailChecking}>
                      {buttonText}
                    </ButtonCta>
                  </FieldsetItem>
                </Fieldset>
              </Form>
            )
          }}
        </Formik>
      </Wrapper>
    </Section>
  )
}

export default CreationFormFirstStep
