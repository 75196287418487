import { createUserApi } from 'api/users/usersApi'
import { agentUpdateUser } from 'api/agents'
import { failUserSignup, failUserUpdate } from 'api/auth'

export const USER_ACTIONS = { create: 'create', update: 'update' }

export const createOrUpdateUser = async payload => {
  const requestBody = {
    user: payload.user,
    agentJWT: payload.agentJWT,
    storeName: 'vype'
  }

  // Create
  if (payload.action === USER_ACTIONS.create) {
    try {
      if (!navigator.onLine) throw new Error("No internet connection")

      await createUserApi(requestBody)
      return { success: true }
    } catch (error) {
      const payload = {
        userData: requestBody,
        error: {
          status: error?.status,
          message: error?.statusText || error?.message || error
        }
      }

      try {
        await failUserSignup(payload)
      } catch (failUserError) {
        console.log('failUserError', failUserError)
      }

      return { success: false }
    }
  } else if (payload.action === USER_ACTIONS.update) {
    // Update
    try {
      if (!navigator.onLine) throw new Error("No internet connection")

      await agentUpdateUser(requestBody)
      return { success: true }
    } catch (error) {
      const payload = {
        userData: requestBody,
        error: {
          status: error?.status,
          message: error?.statusText || error?.message || error
        }
      }

      try {
        await failUserUpdate(payload)
      } catch (failUserError) {
        console.log('failUserError', failUserError)
      }

      return { success: false }
    }
  }
}
