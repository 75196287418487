import React, { useState, useEffect } from 'react'
import { node } from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import { getAgentAppData, agentSourcesByChannel } from 'api/agents'
import { agentLogin } from 'api/auth'
import { useLocalForage } from 'contexts/LocalForage'
import { formatDropdownOptions } from 'utils/formatters'

const SessionContext = React.createContext()

let SessionProvider = props => {
  const [isOnline, setIsOnline] = useState(true)
  const [sessionError, setSessionError] = useState('')
  const [loggedIn, setLoggedIn] = useState(false)
  const [agentInfo, setAgentInfo] = useState(null)
  const [agentErpSource, setAgentErpSource] = useState([])
  const [agentSources, setAgentSources] = useState({})
  const { getStoredItem, setStoredItem, removeStoredItems, clearStorage } = useLocalForage()
  const navigate = useNavigate()
  const location = useLocation()

  const checkResetSinceNewVersionWithDate = async () => {
    const storedDate = await getStoredItem('hasResetSinceNewVersion')
    const currentDate = new Date()

    if (storedDate === null || storedDate === true || (storedDate && storedDate.getDate() !== currentDate.getDate())) {
      await clearStorage()
      setStoredItem('hasResetSinceNewVersion', currentDate)
      window.location.reload()
    }
  }

  // Fetch agent-app data
  const fetchAgentAppData = async () => {
    // Check if not already stored
    const isDataFetched = await getStoredItem('isDataFetched')

    if (!isDataFetched) {
      try {
        // If not already stored, fetch from backend
        const datas = await getAgentAppData()

        let flavoursBasedOnDevice = {}
        // eslint-disable-next-line camelcase
        datas.devicesGiven.forEach(({ value, flavours, max_flavours }) => {
          flavoursBasedOnDevice = {
            ...flavoursBasedOnDevice,
            [value]: {
              options: formatDropdownOptions(flavours),
              max: max_flavours
            }
          }
        })

        const formatedData = Object.keys(datas).reduce((allDatas, dataKey) => {
          return {
            ...allDatas,
            [dataKey]: formatDropdownOptions(datas[dataKey])
          }
        }, {})

        setStoredItem('agentSourceOptions', formatedData.agentSources)
        setStoredItem('deviceGivenOptions', formatedData.devicesGiven)
        setStoredItem('flavoursGivenOptions', flavoursBasedOnDevice)
        setStoredItem('preferredBrandVape', formatedData.preferredBrandVape)
        setStoredItem('preferredBrandCigarette', formatedData.preferredBrandCigarette)
        setStoredItem('isDataFetched', true)
      } catch (err) {
        console.log(err)
      }
    }
  }

  const checkJWT = async () => {
    const agentJWT = await getStoredItem('agentJWT')
    const agentInformation = await getStoredItem('agentInformation')

    if (agentJWT) setLoggedIn(true)
    if (agentInformation) setAgentInfo(agentInformation)
    if (!agentJWT && location.pathname !== '/login') navigate('/login')
  }

  const handleStorage = async () => {
    await checkResetSinceNewVersionWithDate()
    await fetchAgentAppData()
    await checkJWT()
  }

  useEffect(() => {
    handleStorage()
  }, [])

  useEffect(() => {
    window.addEventListener('offline', () => setIsOnline(false))
    window.addEventListener('online', () => setIsOnline(true))
  }, [])

  useEffect(() => {
    setSessionError('')
  }, [location])

  useEffect(() => {
    const getErpSources = async () => {
      const agentErpSource = await getStoredItem('agentErpSource')
      // call getAgentSourceAndErp if no data is present in local storage for performance
      if (!agentErpSource?.length > 0) getAgentSourceAndErp()
      else setAgentErpSource(agentErpSource)
    }
    if (loggedIn) getErpSources()
    // getting sources, erp by api which is associated to each agent after login only
  }, [loggedIn])

  const login = async values => {
    try {
      const { token, agent } = await agentLogin(values)
      const agentInformation = {
        name: agent.name,
        id: agent.id,
        username: agent.username,
        banner: agent.banner
      }

      setLoggedIn(true)
      setAgentInfo(agentInformation)
      setStoredItem('agentJWT', token)
      setStoredItem('agentInformation', agentInformation)
      setSessionError('')
    } catch (err) {
      setSessionError(
        'Agent pin or username is incorrect. Try again, and if the problem persists, please reach out to your channel representative for assistance.'
      )
    }
  }

  const logout = async () => {
    setLoggedIn(false)
    setAgentInfo(null)
    removeStoredItems(['agentJWT', 'agentSource', 'agentErpSource', 'darkMarket', 'agentInformation'])
    navigate('/login')
    window.location.reload()
  }

  const isValidAgentPin = async values =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await agentLogin(values)
        resolve(response)
      } catch (error) {
        setSessionError('Agent pin is incorrect.')
        reject(new Error('Agent pin is incorrect.'))
      }
    })

  const getAgentSourceAndErp = async () => {
    try {
      const data = await agentSourcesByChannel()
      const formatedData = Object.keys(data).reduce((allData, dataKey) => {
        return {
          ...allData,
          [dataKey]: formatDropdownOptions(data[dataKey])
        }
      }, {})
      if (Object.keys(formatedData?.sources).length > 0) {
        setStoredItem('agentSourceOptions', formatedData.sources)
        setAgentSources(formatedData.sources)
      }
      setStoredItem('agentErpSource', data.erpSources)
      setAgentErpSource(data.erpSources)
    } catch (error) {
      console.log(error)
    }
  }

  const value = {
    isOnline,
    loggedIn,
    sessionError,
    setSessionError,
    login,
    logout,
    agentInfo,
    isValidAgentPin,
    agentErpSource,
    agentSources
  }

  return <SessionContext.Provider value={value}>{props.children}</SessionContext.Provider>
}

SessionProvider.propTypes = {
  children: node.isRequired
}

const SessionConsumer = SessionContext.Consumer

export { SessionProvider, SessionConsumer, SessionContext }
