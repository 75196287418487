import { agentLoginApi, screenEmailApi, failUserSignupApi, failUserUpdatepApi } from 'api/auth/authApi'

export const agentLogin = payload =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await agentLoginApi(payload)
      resolve(data)
    } catch (error) {
      reject(error.response)
    }
  })

export const screenEmail = email =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await screenEmailApi(email)
      resolve(response)
    } catch (error) {
      reject(error.response)
    }
  })

export const failUserSignup = payload =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await failUserSignupApi(payload)
      resolve(data)
    } catch (error) {
      reject(error.response)
    }
  })

export const failUserUpdate = payload =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await failUserUpdatepApi(payload)
      resolve(data)
    } catch (error) {
      reject(error.response)
    }
  })
