import React, { useContext } from 'react'
import { Form, Formik } from 'formik'
import omit from 'lodash/omit'
import { useAccountCreation } from 'contexts/AccountCreation'
import { MODAL_ACTIONS, useModalContext } from 'contexts/Modal'
import { useLocalForage } from 'contexts/LocalForage'
import { SessionContext } from 'contexts/Session'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import Fieldset from 'components/FormComponents/Fieldset'
import FieldsetItem from 'components/FormComponents/Fieldset/FieldsetItem'
import FieldMessage from 'components/FormComponents/FieldMessage'
import ButtonCta from 'components/Button/ButtonCta'
import Separator from 'components/Separator'
import Dropdown from 'components/FormComponents/Dropdown'
import Referrer from 'components/AccountCreation/CreationFormThirdStep/Referrer'
import BundlesForm from 'components/AccountCreation/CreationFormThirdStep/BundlesForm'
import { getBundle } from 'components/AccountCreation/CreationFormThirdStep/BundlesForm/Bundle'
import {
  validationSchema,
  getInitialValues
} from 'components/ReturningConsumer/ReturningConsumerSecondStep/formValidation'
import PreferredBrand from 'components/AccountCreation/CreationFormThirdStep/PreferredBrand'
import AgentValidationModal from 'components/ReturningConsumer/AgentValidationModal'
import { LAST_USAGE_OPTIONS, USER_TYPE } from 'utils/constants'
import { isEmpty } from 'utils/validators'
import { AGENT_APP_VERSION } from 'utils/version'
import { createOrUpdateUser, USER_ACTIONS } from 'api/users'
import toast from 'react-hot-toast'

const ReturningConsumerSecondStep = () => {
  const { user, setUser, resetUser, resetStep, resetTimer, elapsedTime, agentValidationRequired } = useAccountCreation()
  const [, dispatch] = useModalContext()
  const { isOnline } = useContext(SessionContext)
  const { getStoredItem } = useLocalForage()

  const updateUser = async ({ userToUpdate, resetAgentPinForm, resetUserForm }) => {
    const agentJWT = await getStoredItem('agentJWT')
    const requestBody = { user: userToUpdate, agentJWT, action: USER_ACTIONS.update }

    try {
      const response = await createOrUpdateUser(requestBody)
      resetUserForm()
      resetUser()
      resetStep()
      resetTimer()
      if (resetAgentPinForm) resetAgentPinForm()
      if (response.success) {
        toast.success('User has been updated')
      } else {
        toast.error('Error occurred while submitting user.')
      }
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  const handleSecondStepSubmit = async (values, { resetForm }) => {
    // Filter bundles to keep only bundle with a device or flavours
    const bundlesGiven = values.bundlesGiven.map(bundle => getBundle(bundle)).filter(bundle => bundle)

    const appBuildId = process.env.BUILD_ID
    const appCommitRef = process.env.COMMIT_REF

    const userToUpdate = {
      ...omit(user, ['email']),
      ...values,
      bundlesGiven,
      agentAppTracking: {
        elapsedTime,
        appBuildId,
        appCommitRef,
        appVersion: AGENT_APP_VERSION,
        userType: USER_TYPE.returning
      }
    }

    setUser(userToUpdate)

    // If we need to validate agent pin, open modal
    if (agentValidationRequired) {
      dispatch({
        type: MODAL_ACTIONS.open,
        payload: {
          content: (
            <AgentValidationModal updateUser={updateUser} userToUpdate={userToUpdate} resetUserForm={resetForm} />
          )
        }
      })
    } else {
      // else add directly the userToUpdate to the queue
      await updateUser({ userToUpdate, resetUserForm: resetForm })
    }
  }

  return (
    <Section title={'Help us better understand our consumers'}>
      <Wrapper>
        <Formik
          initialValues={getInitialValues(user)}
          validationSchema={validationSchema}
          onSubmit={handleSecondStepSubmit}
          validateOnChange
        >
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => {
            return (
              <Form>
                <Fieldset>
                  <FieldsetItem xs="1-1">
                    <Dropdown
                      name="lastVuseProductUsed"
                      label="When was the last time the consumer used vuse?"
                      value={values.lastVuseProductUsed || ''}
                      options={LAST_USAGE_OPTIONS}
                      onChange={handleChange}
                      placeholder="Select value"
                      meta={{
                        error: errors.lastVuseProductUsed,
                        touched: touched.lastVuseProductUsed
                      }}
                      onBlur={handleBlur}
                      full
                    />
                  </FieldsetItem>
                </Fieldset>

                <Fieldset>
                  <FieldsetItem xs="1-1">
                    <Referrer
                      label="Was the consumer Invited by a friend?"
                      name="userRefererId"
                      optional={2}
                      referrerUserEmail={user.referrerUserEmail}
                    />
                  </FieldsetItem>
                </Fieldset>

                <BundlesForm name="bundlesGiven" />

                <PreferredBrand />

                <Separator />

                <Fieldset>
                  <FieldsetItem xs="1-1">
                    <ButtonCta type="submit" disabled={!isEmpty(errors) || !isOnline} isLoading={isSubmitting || !isOnline}>
                      Confirm and submit
                    </ButtonCta>
                  </FieldsetItem>
                  <FieldsetItem xs="1-1">
                    {!isOnline && <FieldMessage align={'center'}>Internet connection problems</FieldMessage>}
                  </FieldsetItem>
                </Fieldset>
              </Form>
            )
          }}
        </Formik>
      </Wrapper>
    </Section>
  )
}

export default ReturningConsumerSecondStep
