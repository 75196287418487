import * as yup from 'yup'
import {
  dobIsUnderaged,
  dobIsValid,
  isPhoneNumberValid,
  isPostalCodeValid,
  isPostalCodeProvinceValid,
  getProvinceFromPostalCode
} from 'utils/validators'
import { CANADIAN_LEGAL_AGES, ADRESS_ERROR_MESSAGE, ALLOWED_PROVINCES } from 'utils/constants'

export const getInitialValues = user => {
  return {
    isSmoker: user.isSmoker || false,
    isVaper: user.isVaper || false,
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    province: user.province || '',
    country: user.country || 'Canada',
    postalCode: user.postalCode || '',
    dob: user.dob || '',
    phoneNumber: user.phoneNumber || '',
    gender: user.gender || null,
    smokeFrequency: user.smokeFrequency || '',
    vapeFrequency: user.vapeFrequency || ''
  }
}

export const validationSchema = (agentProvince) => {
  return yup.lazy(values => {
    let schema = {
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
      phoneNumber: yup
        .string()
        .test('phoneNumber', 'Phone number is invalid', () => isPhoneNumberValid(values.phoneNumber)),
      dob: yup
        .string()
        .required('Date of birth is required')
        .test('dob', 'Date of birth is invalid', dobIsValid()),
      postalCode: yup
        .string()
        .required('Postal code is required')
        .test('postalCode', 'Invalid postal code', isPostalCodeValid())
        .test('postalCode', ADRESS_ERROR_MESSAGE, isPostalCodeProvinceValid())
    }

    if (values.isSmoker) {
      schema = {
        ...schema,
        smokeFrequency: yup.string().required('Frequency is required')
      }
    }

    if (values.isVaper) {
      schema = {
        ...schema,
        vapeFrequency: yup.string().required('Frequency is required')
      }
    }

    if (values.postalCode) {
      const provinceFromPostalCode = getProvinceFromPostalCode(values.postalCode)

      if (provinceFromPostalCode && ALLOWED_PROVINCES.includes(provinceFromPostalCode)) {
        schema.dob = schema.dob.test(
          'dob',
          `Must be at least ${CANADIAN_LEGAL_AGES[provinceFromPostalCode]}`,
          dobIsUnderaged(provinceFromPostalCode)
        )
      } else {
        schema.dob = schema.dob.test(
          'dob',
          'Please enter a valid postal code first to determine the minimum age requirement for your province.',
          () => false
        )
      }
    } else {
      // If no postalCode, verify dob with agent province
      schema.dob = schema.dob.test(
        'dob',
        `Must be at least ${CANADIAN_LEGAL_AGES[agentProvince]}`,
        dobIsUnderaged(agentProvince)
      )
    }

    return yup.object().shape(schema)
  })
}
